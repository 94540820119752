import React from 'react';
import Helmet from 'react-helmet';

const PreloadImages = ({ images }) => {
  return (
    <>
      <Helmet>
        {images.map((image, i) => (
          <link key={i} rel="preload" href={image} as="image" />
        ))}
      </Helmet>
      {images.map((image, i) => (
        <div
          key={i}
          className="visually-hidden"
          style={{ background: `url(${image})` }}
        />
      ))}
    </>
  );
};

export default PreloadImages;
