import React from 'react';

const Spanify = ({ text }) => {
  const letters = text.split('');
  return (
    <>
      {letters.map((letter, i) => (
        <span key={i}>{letter === ' ' ? '\u00A0' : letter}</span>
      ))}
    </>
  );
};

export default Spanify;
