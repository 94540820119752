import { useState, useEffect } from 'react';

const useIphoneDetection = () => {
  const [isIphone, setIsIphone] = useState(false);

  useEffect(() => {
    setIsIphone(/iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
  }, []);

  return isIphone;
};

export default useIphoneDetection;
