import React, { useState, createContext } from 'react';

export const AppContext = createContext({
  theme: '',
  totalSlides: 0,
  slide: 1,
  info: '',
  setSlide: () => {},
  setInfo: () => {},
  setTheme: () => {},
  setTotalSlides: () => {},
  goToFirstSlide: () => {},
});

export const AppProvider = ({ children }) => {
  const [slide, setSlide] = useState(1);
  const [info, setInfo] = useState('');
  const [theme, setTheme] = useState('light');
  const [totalSlides, setTotalSlides] = useState(0);

  const goToFirstSlide = () => {
    setSlide(1);
  };

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
        slide,
        setSlide,
        info,
        setInfo,
        totalSlides,
        setTotalSlides,
        goToFirstSlide,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
