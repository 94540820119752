import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import { clamp } from 'lodash-es';
import { AppContext } from '../../AppContext';
import { LocaleContext } from '../Layout';
import './Nav.scss';

const Nav = ({
  toggleMenu,
  showLogo,
  location,
  isFullScreen,
  toggleFullScreen,
}) => {
  const [disabled, setDisabled] = useState(false);
  const { theme, totalSlides, slide, setSlide, info, goToFirstSlide } =
    useContext(AppContext);
  const { locale } = useContext(LocaleContext);

  const clickHandler = () => {
    setDisabled(true);
    toggleMenu();
    if (showLogo)
      setTimeout(() => {
        setDisabled(false);
      }, 1300);
    else setDisabled(false);
  };

  return (
    <nav className={`nav ${theme}`}>
      <div
        className={showLogo ? 'burger cheese' : 'burger ham'}
        onClick={clickHandler}
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      >
        <span className="salad"></span>
      </div>
      <Link
        to={locale === 'en' ? '/' : '/es'}
        state={{
          disableAnimations: true,
          activeSection:
            locale === 'en'
              ? location.pathname.split('/')[1]
              : location.pathname.split('/')[2],
        }}
      >
        <span className="logo" style={{ opacity: showLogo ? '1' : '0' }}>
          <svg
            width="141"
            height="16"
            viewBox="0 0 141 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.31379 1.20288H1V15.3865H6.31379C10.8396 15.3865 13.7491 12.4972 13.7491 8.29467C13.7491 4.07192 10.8396 1.20288 6.31379 1.20288V1.20288ZM6.09154 12.3962H4.45497V4.15274H6.09154C8.69792 4.15274 10.2335 5.68828 10.2335 8.29467C10.2335 10.901 8.69792 12.3962 6.09154 12.3962Z"
              stroke="white"
              strokeWidth="1"
              strokeMiterlimit="2"
              strokeLinejoin="round"
              className="diego"
            />
            <path
              d="M20.1571 1.20288H16.7021V15.3865H20.1571V1.20288Z"
              stroke="white"
              strokeWidth="1"
              strokeMiterlimit="2"
              strokeLinejoin="round"
              className="diego"
            />
            <path
              d="M32.3563 3.9709V1.20288H23.6684V15.3865H32.3563V12.6184H27.1234V9.50694H31.7502V6.83994H27.1234V3.9709H32.3563V3.9709Z"
              stroke="white"
              strokeWidth="1"
              strokeMiterlimit="2"
              strokeLinejoin="round"
              className="diego"
            />
            <path
              d="M48.5204 5.68586C47.7728 2.77641 45.3079 1.03882 41.9135 1.03882C37.6706 1.03882 34.7005 4.02909 34.7005 8.27204C34.7005 12.515 37.6908 15.485 41.9337 15.485C45.6716 15.485 48.0355 13.0403 48.7628 10.5956V7.38304H41.2468V9.92881H45.6311C45.1058 11.525 43.9138 12.4342 42.1964 12.4342C39.7112 12.4342 38.2161 10.8986 38.2161 8.27204C38.2161 5.80708 39.691 4.23113 41.9135 4.23113C43.2066 4.23113 44.1764 4.73625 44.7017 5.68586H48.5204V5.68586Z"
              stroke="white"
              strokeWidth="1"
              strokeMiterlimit="2"
              strokeLinejoin="round"
              className="diego"
            />
            <path
              d="M58.7238 15.527C62.7445 15.527 65.9772 12.5166 65.9772 8.25342C65.9772 3.99027 62.7647 1 58.7238 1C54.7031 1 51.4299 3.99027 51.4299 8.25342C51.4299 12.5166 54.7031 15.527 58.7238 15.527ZM58.7238 12.3751C56.4204 12.3751 54.9657 10.7386 54.9657 8.25342C54.9657 5.72786 56.4204 4.1317 58.7238 4.1317C60.9867 4.1317 62.4616 5.72786 62.4616 8.25342C62.4616 10.7386 60.9867 12.3751 58.7238 12.3751Z"
              stroke="white"
              strokeWidth="1"
              strokeMiterlimit="2"
              strokeLinejoin="round"
              className="diego"
            />
            <path
              d="M79.6778 5.80951C79.6778 6.92076 79.0717 7.58751 77.7786 7.58751H75.6369V4.07192H77.7786C79.0717 4.07192 79.6778 4.75888 79.6778 5.80951ZM77.9806 1.20288H72.1819V15.3865H75.6369V10.0323H76.4653L79.4152 15.3865H83.3146L80.0415 9.7696C82.2236 9.12305 83.1934 7.44608 83.1934 5.66808C83.1934 3.20313 81.4558 1.20288 77.9806 1.20288Z"
              fill="white"
              className="ravier"
            />
            <path
              d="M95.0343 15.3865H98.6913L93.5593 1.20288H89.5588L84.4269 15.3865H88.0435L88.8921 12.8811H94.1857L95.0343 15.3865ZM93.2967 10.2141H89.8013L91.5389 5.02153L93.2967 10.2141Z"
              fill="white"
              className="ravier"
            />
            <path
              d="M107.884 1.20288L104.348 11.9113L100.832 1.20288H97.1552L102.186 15.3865H106.51L111.541 1.20288H107.884Z"
              fill="white"
              className="ravier"
            />
            <path
              d="M116.429 1.20288H112.974V15.3865H116.429V1.20288Z"
              fill="white"
              className="ravier"
            />
            <path
              d="M127.622 3.9709V1.20288H118.935V15.3865H127.622V12.6184H122.389V9.50694H127.016V6.83994H122.389V3.9709H127.622Z"
              fill="white"
              className="ravier"
            />
            <path
              d="M137.363 5.80951C137.363 6.92076 136.757 7.58751 135.464 7.58751H133.322V4.07192H135.464C136.757 4.07192 137.363 4.75888 137.363 5.80951ZM135.666 1.20288H129.867V15.3865H133.322V10.0323H134.151L137.101 15.3865H141L137.727 9.7696C139.909 9.12305 140.879 7.44608 140.879 5.66808C140.879 3.20313 139.141 1.20288 135.666 1.20288Z"
              fill="white"
              className="ravier"
            />
          </svg>
        </span>
      </Link>
      {totalSlides !== 0 ? (
        <div className="indicator">
          {info && (
            <button
              className="image-description"
              aria-label={info}
              data-balloon-pos="down-right"
              data-balloon-length="large"
            >
              i
            </button>
          )}
          {slide > 1 ? (
            <div className="first-slide" onClick={goToFirstSlide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                className="svg-inline--fa fa-angle-double-left fa-w-14"
                data-icon="angle-double-left"
                data-prefix="fas"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M223.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L319.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L393.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34zm-192 34l136 136c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9L127.9 256l96.4-96.4c9.4-9.4 9.4-24.6 0-33.9L201.7 103c-9.4-9.4-24.6-9.4-33.9 0l-136 136c-9.5 9.4-9.5 24.6-.1 34z"
                ></path>
              </svg>
            </div>
          ) : null}
          <div
            className="prev-slide"
            onClick={() => setSlide(clamp(slide - 1, 1, totalSlides))}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="angle-left"
              className="svg-inline--fa fa-angle-left fa-w-8"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 512"
            >
              <path
                fill="currentColor"
                d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
              ></path>
            </svg>
          </div>
          <div>
            {slide}&nbsp; ∕&nbsp;&nbsp;{totalSlides}
          </div>
          <div
            className="next-slide"
            onClick={() => setSlide(clamp(slide + 1, 1, totalSlides))}
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="angle-right"
              className="svg-inline--fa fa-angle-right fa-w-8"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 512"
            >
              <path
                fill="currentColor"
                d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
              ></path>
            </svg>
          </div>
          <div
            className="fullscreen"
            onClick={() => toggleFullScreen(isFullScreen)}
          >
            {isFullScreen ? (
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="compress"
                className="svg-inline--fa fa-compress fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M436 192H312c-13.3 0-24-10.7-24-24V44c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v84h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-276-24V44c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v84H12c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24zm0 300V344c0-13.3-10.7-24-24-24H12c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-84h84c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12H312c-13.3 0-24 10.7-24 24v124c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12z"
                ></path>
              </svg>
            ) : (
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="expand"
                className="svg-inline--fa fa-expand fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H64v84c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM288 44v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12V56c0-13.3-10.7-24-24-24H300c-6.6 0-12 5.4-12 12zm148 276h-40c-6.6 0-12 5.4-12 12v84h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V332c0-6.6-5.4-12-12-12zM160 468v-40c0-6.6-5.4-12-12-12H64v-84c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z"
                ></path>
              </svg>
            )}
          </div>
        </div>
      ) : (
        <div className="indicator">
          <div
            className="fullscreen"
            onClick={() => toggleFullScreen(isFullScreen)}
          >
            {isFullScreen ? (
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="compress"
                className="svg-inline--fa fa-compress fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M436 192H312c-13.3 0-24-10.7-24-24V44c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v84h84c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-276-24V44c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v84H12c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24zm0 300V344c0-13.3-10.7-24-24-24H12c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-84h84c6.6 0 12-5.4 12-12v-40c0-6.6-5.4-12-12-12H312c-13.3 0-24 10.7-24 24v124c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12z"
                ></path>
              </svg>
            ) : (
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="expand"
                className="svg-inline--fa fa-expand fa-w-14"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H64v84c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM288 44v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12V56c0-13.3-10.7-24-24-24H300c-6.6 0-12 5.4-12 12zm148 276h-40c-6.6 0-12 5.4-12 12v84h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V332c0-6.6-5.4-12-12-12zM160 468v-40c0-6.6-5.4-12-12-12H64v-84c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z"
                ></path>
              </svg>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Nav;
